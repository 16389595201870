import React from "react";
import "./Dashboard.css";
import { useStateContext } from "../contexts/ContextProvider";
import Text3D from "../components/Text3D"; // Import the renamed component
import ContactCard from "../components/ContactCard";
import shapeImage from "../assets/images/shape.webp"; // Correct relative path
import { motion } from "framer-motion";
import { Launch } from '@mui/icons-material';
import StretchButton from "../components/StretchButton";
import FeaturedToolsCard from "../components/FeaturedToolsCard";
import AboutCard from "../components/AboutCard";


// Define variants for animations
const topEntry = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

const leftEntry = {
  hidden: { x: -100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.8 } },
};

const bottomEntry = {
  hidden: { y: 100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

const rightEntry = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.8 } },
};

const zoomIn = {
  hidden: { scale: 0.5, opacity: 0 },
  visible: { scale: 1, opacity: 1, transition: { duration: 0.8 } },
};

const Dashboard = () => {
  const { activeMenu } = useStateContext(); // Access activeMenu state
  return (
    <div className="dashboard">
      <div id="base">
        <div className="content">
          <div className="grid-container">
            <motion.div
              className="item1 text-white text-center"
              variants={topEntry}
              initial="hidden"
              animate="visible"
            >
              <StretchButton
                href="https://example.com"
                text="Featured Research"
                icon={<Launch />}
              />
            </motion.div>
            <motion.div
              className="item2 bg-transparent text-white text-center"
              variants={zoomIn}
              initial="hidden"
              animate="visible"
            >
              <Text3D text="Welcome" />
            </motion.div>
            <motion.div
              className="item3 text-white text-center"
              variants={topEntry}
              initial="hidden"
              animate="visible"
            >
              <StretchButton
                href="https://example.com"
                text="Latest post"
                icon={<Launch />}
              />
            </motion.div>
            <motion.div
              className="item4 text-white text-center border-animation"
              variants={leftEntry}
              initial="hidden"
              animate="visible"
            >
              <FeaturedToolsCard />
            </motion.div>
            <motion.div
              className="item5 text-white text-center border-animation px-2"
              variants={bottomEntry}
              initial="hidden"
              animate="visible"
            >
            <AboutCard/>
            </motion.div>
            <motion.div
              className="item6 text-white text-center border-animation"
              variants={rightEntry}
              initial="hidden"
              animate="visible"
            >
              <ContactCard/>
            </motion.div>
          </div>
        </div>
        <div
          id="hey"
          className="w-full bg-[#011] absolute right-0 left-0 mx-auto z-10 transform perspective-[200px]
            desktop1280:bottom-[27.3vh] desktop1440:bottom-[28vh] 
            desktop1600:bottom-[28vh] desktop1920:bottom-[27vh]"
        >
          <div id="layer-up"></div>
        </div>

        <div
          id="layer-0"
          className={`${activeMenu ? "w-[calc(200vw-28rem)]" : "w-[200vw]"}`}
        >
          <div id="layer-1">
            <div id="layer-2">
              <div id="lines">
                <div id="layer-corner"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
