import React from "react";
import { motion } from "framer-motion";
import { TextField, Button } from "@mui/material";
import StackChart from "../components/charts/StackChart";
import { IconButton } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// Define animation variants for the headline
const fadeIn = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0, transition: { duration: 1 } },
};

// Input object for real data (replace with actual data later)
const tokenData = {
  symbol: "XLM",
  contractAddress: "xx...xxxx",
  dexScreenerLink: "https://dexscreener.com/",
  time:"16/01/25 12:46",
  supply:"73",
};

const HoldersStructure = () => {
  return (
    <div className="holders-structure p-4">
      {/* Headline Section */}
      <motion.h1
        className="text-4xl font-light text-center bg-gradient-to-r from-blue-400 via-green-400 to-purple-600 bg-clip-text text-transparent mb-12"
        variants={fadeIn}
        initial="hidden"
        animate="visible"
      >
        Holders Structure Info
      </motion.h1>

      {/* Centered Container */}
      <div className="flex flex-col items-center justify-center w-full md:w-3/5 mx-auto gap-8">
        {/* Top Section */}
        <div className="flex items-center w-full gap-4">
          <label className="text-lg text-white whitespace-nowrap">Sol Token CA:</label>
          <TextField
            variant="outlined"
            placeholder="Enter CA"
            autoComplete="off"
            sx={{
              flexGrow: 1,
              input: {
                color: "rgba(255, 255, 255, 0.78)",
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "rgba(156, 163, 175, 0.4)",
                },
                "&:hover fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.4)",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "rgba(255, 255, 255, 0.5)",
                },
              },
            }}
          />
          <Button
            variant="outlined"
            size="large"
            sx={{
              borderColor: "rgba(156, 163, 175, 0.5)",
              borderWidth: "2px",
              color: "rgba(255, 255, 255, 0.78)",
              textTransform: "none",
              transition: "all 0.3s ease",
              "&:hover": {
                borderColor: "transparent",
                borderImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6) 1",
                color: "rgb(255, 255, 255)",
              },
            }}
          >
            Submit
          </Button>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center w-full md:w-3/5 mx-auto mt-8">
        {/* 2 Section */}
        <div className="flex items-center justify-center w-full gap-4">
          {/* Symbol Section */}
          <div className="text-lg mx-4 text-white">
          Supply: {tokenData.supply}%
          </div>
          <div className="text-lg mx-4 text-white">
          {tokenData.time}
          </div>
          <div className="text-lg mx-4 text-white">
            Symbol: {tokenData.symbol}
          </div>

          {/* Contract Address Section */}
          <div className="text-lg text-white mx-4 flex items-center gap-2">
            <strong>CA:</strong> {tokenData.contractAddress}
            <IconButton
              size="small"
              onClick={() => navigator.clipboard.writeText(tokenData.contractAddress)}
              title="Copy to Clipboard"
              sx={{
                color: "rgba(255, 255, 255, 0.78)",
                "&:hover": {
                  color: "#3b82f6",
                },
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </div>
          
          {/* DexScreener Section */}
          <div className="text-lg">
            <a
              href={tokenData.dexScreenerLink}
              target="_blank"
              rel="noopener noreferrer"
              className="underline text-white hover:text-blue-400"
              title="Open DexScreener"
            >
              DexScreener
            </a>
          </div>
        </div>
      </div>

      {/* ChartSection */}
      <div className="holders-structure p-4">
      <div className="flex items-center justify-center w-[90%]" style={{ paddingLeft: "5%" }}>
        <div className="text-white w-full">
          <StackChart />
        </div>
      </div>
      </div>
      <div className="flex flex-col items-center justify-center w-full md:w-3/5 mx-auto mt-8">
        {/* 3 Section */}
        <div className="flex items-center justify-center w-full gap-4">
          {/* about dialog */}
          <div className="text-lg mx-4 text-white">
          About this tool
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoldersStructure;
