import React, {useEffect, useState} from 'react';
import avatar from '../data/avatar6.png'
import { motion } from 'framer-motion'; // Import motion from framer-motion
import visionIcon from '../assets/images/vision_icon.png'; // Adjust the path as needed


import { useStateContext } from '../contexts/ContextProvider';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';


  // Animation variants for fading in and out
  const fadeVariants = {
    hidden: { opacity: 0 }, // Completely hidden
    visible: { opacity: 0.75, transition: { duration: 0.5 } }, // Fade to 75% opacity
  };


const Navbar = () => {
  const {activeMenu, setActiveMenu, isClicked, setIsClicked, handleClick, screenSize,setScreenSize} = useStateContext();

  const [profileVisible, setProfileVisible ] = useState(false);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [])


  useEffect(() => {
    if(screenSize <= 900) { setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  },[screenSize]);

  

  return (
    <div className='flex justify-between p-1 md:mx-6 '>
      
        <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        className="p-5 pb-2"
        onClick={() => setActiveMenu((prevActiveMenu) => !prevActiveMenu)}
      >
        {activeMenu ? (
          <motion.div
          initial={{ x: 50 }}
          animate={{ x: activeMenu ? 0 : 50 }} // Adjust position based on activeMenu
          transition={{ type: 'spring', stiffness: 300, damping: 27 }}
          >
            <KeyboardDoubleArrowLeftIcon
              fontSize="large"
              style={{
                fill: 'url(#gradient)',
              }}
            />

          </motion.div>
        ) : (
          <MenuIcon
            style={{
              fill: 'url(#gradient)',
            }}
          />
        )}
        <svg width="0" height="0">
          <defs>
            <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: '#ec4899', stopOpacity: 1 }} /> {/* pink-500 */}
              <stop offset="50%" style={{ stopColor: '#10b981', stopOpacity: 1 }} /> {/* green-500 */}
              <stop offset="100%" style={{ stopColor: '#3b82f6', stopOpacity: 1 }} /> {/* blue-500 */}
            </linearGradient>
          </defs>
        </svg>
      </IconButton>
      {/* Tools Logo */}
      <motion.div
        className="flex items-center space-x-2"
        initial={{opacity: 0 }}
        animate={activeMenu ? {opacity: 0 } : {opacity: 0.6 }}
      >
        <span className="absolute left-1/2 -translate-x-1/2 text-2xl italic opacity-75 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-green-500 to-blue-500 barlowLogo tracking-wide">
          Wide screen mode
        </span>
      </motion.div>


        <div className='flex'>


          <div className='flex items-center gap-2 cursor-pointer p-2 sm:hover:bg-light-gray   rounded-lg ml-2'
          onClick={() => {
            setProfileVisible( () => setProfileVisible( (prev) => !prev))
          }}>
            
            <img src = {avatar}  className='rounded-full w-8 h-8' />
            <p>
              <span className='text-gray-400  font-semibold ml-1 text-14'> User </span>
            </p>
            <KeyboardArrowDownIcon className='text-gray-400 text-12'/>
          </div>
        </div>

        
    </div>
  )
}


export default Navbar