import React from "react";
import { Typography, Divider, IconButton, Button } from "@mui/material";
import { ArrowLeft, ArrowRight, PieChartOutlineRounded, ShowChart, Insights } from "@mui/icons-material";
import { motion } from "framer-motion";

const features = [
  {
    title: "Holders Structure Info",
    description: "Realtime check what is going on with holders. Do new wallets jump in or old ones go out...",
    icon: <PieChartOutlineRounded />, 
    extra: "Track real-time wallet activities for deeper insights into holder behaviors."
  },
  {
    title: "Price Action Breakdown",
    description: "Gain insights into price action with advanced tools. Identify support and resistance zones quickly.",
    icon: <ShowChart />, 
    extra: "Utilize cutting-edge algorithms to predict market trends effectively."
  },
  {
    title: "Unrealized PnL",
    description: "Understand potential profit or loss scenarios for better decision-making. Monitor your portfolio's health.",
    icon: <Insights />, 
    extra: "Monitor your portfolio’s health with intuitive profit and loss calculators."
  },
];

const FeaturedToolsCard = () => {
  const [currentFeature, setCurrentFeature] = React.useState(0);
  const [direction, setDirection] = React.useState("left"); // Direction state for animation

  const handlePrev = () => {
    setDirection("left");
    setCurrentFeature((prev) => (prev - 1 + features.length) % features.length);
  };

  const handleNext = () => {
    setDirection("right");
    setCurrentFeature((prev) => (prev + 1) % features.length);
  };

  const variants = {
    enter: (direction) => ({
      x: direction === "left" ? -50 : 50,
      opacity: 0,
    }),
    center: { x: 0, opacity: 1 },
    exit: (direction) => ({
      x: direction === "left" ? 100 : -100,
      opacity: 0,
    }),
  };

  return (
    <div className="relative w-full h-full bg-transparent flex items-center justify-center">
      {/* Left Arrow */}
      <IconButton
          onClick={handlePrev}
          className="text-gray-400 hover:text-white absolute left-0 top-1/2 transform -translate-y-1/2"
        >
          <ArrowLeft />
        </IconButton>

        {/* Right Arrow */}
        <IconButton
          onClick={handleNext}
          className="text-gray-400 hover:text-white absolute right-0 top-1/2 transform -translate-y-1/2 "
        >
          <ArrowRight />
        </IconButton>
      <motion.div
        className="w-full max-w-[92%] text-white bg-transparent"
        custom={direction}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: 0.5 }}
        key={currentFeature}
      >
        {/* Icon */}
        <Typography className="flex justify-center items-center text-3xl mb-2 mt-4">
          {features[currentFeature].icon}
        </Typography>

        {/* Title with Max Space Between Arrows */}
        <div className="flex items-center justify-between w-full relative">
          

        <Typography className="text-center font-bold flex-1 mx-6 text-lg desktop1680:text-xl desktop1920:text-2xl desktop2560:text-3xl">
            {features[currentFeature].title}
        </Typography>

          
        </div>

        {/* Divider */}
        <Divider className="my-2 desktop1600:my-4 border-gray-600 mx-auto w-[80%] desktop2560:my-10" />

        {/* Description */}
        <Typography className="text-center text-sm px-6 mb-3 desktop1680:mb-6 desktop1920:text-lg">
          {features[currentFeature].description}
        </Typography>

        {/* Extra Sentence for Screens Above 1600px */}
        <Typography className="hidden desktop1600:block text-center text-sm px-6 desktop1920:text-lg">
          
            {features[currentFeature].extra}
          
        </Typography>

        {/* Button */}
        <div className="flex justify-center">
          <Button
            variant="outlined"
            className="my-2 desktop1440:my-6 desktop2560:my-10 desktop1920:py-2 desktop2560:py-4  px-8 desktop1920:px-8 desktop2560:px-10 text-sm desktop1920:text-lg desktop2560:text-xl"
            sx={{
              borderColor: "rgba(156, 163, 175, 0.5)",
              borderWidth: "2px",
              color: "rgba(156, 163, 175, 1)",
              textTransform: "none",
              transition: "all 0.3s ease",
              "&:hover": {
                borderWidth: "2px",
                borderColor: "transparent",
                borderImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6) 1",
                color: "rgba(255, 255, 255, 1)",
              },
            }}
            >
              Open
            </Button>
        </div>
      </motion.div>
    </div>
  );
};

export default FeaturedToolsCard;
