import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { StyledEngineProvider } from '@mui/material/styles';
import './App.css';

import { Dashboard, Login, Profile, Settings, HoldersStructure } from './pages';
import { Navbar, Sidebar } from './components';
import { useStateContext } from './contexts/ContextProvider';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const cache = createCache({
  key: 'css',
  prepend: true, // Ensure Emotion's styles are injected first
});

const App = () => {
  const { activeMenu } = useStateContext();

  return (
    <CacheProvider value={cache}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <div className="flex relative bg-main-bg h-screen">
            {/* Sidebar */}
            <div
              className={`fixed top-0 left-0 h-screen bg-main-bg transition-all duration-200 z-50 ${
                activeMenu ? 'w-56' : 'w-0'
              }`}
            >
              <Sidebar />
            </div>

            {/* Main Content */}
            <div
              className={`flex flex-col transition-all duration-200 bg-transparent ${
                activeMenu ? 'md:ml-56' : 'md:ml-0'
              } w-full h-screen`}
            >
              {/* Navbar */}
              <div className="sticky top-0 bg-main-dark-bg navbar z-40 w-full">
                <Navbar />
              </div>

              {/* Scrollable Content Area */}
              <div className="relative flex-1 overflow-auto">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/Dashboard" element={<Dashboard />} />
                  <Route path="/Profil" element={<Profile />} />
                  <Route path="/Breakdown" element={<Settings />} />
                  <Route path="/Login" element={<Login />} />
                  <Route path="/Structure info" element={<HoldersStructure />} />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </StyledEngineProvider>
    </CacheProvider>
  );
};

export default App;
