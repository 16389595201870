import React from "react";
import { motion } from "framer-motion";
import { Button } from "@mui/material";

const topEntry = {
  hidden: { y: -100, opacity: 0 },
  visible: { y: 0, opacity: 1, transition: { duration: 0.8 } },
};

const StretchButton = ({ href = "#", text = "Click Me", icon = null }) => {
  return (
    <motion.div
      className="w-full h-full flex items-center justify-center" // Full width/height of grid cell and center content
      variants={topEntry}
      initial="hidden"
      animate="visible"
    >
      <Button
        variant="outlined"
        href={href}
        endIcon={icon}
        sx={{
          width: "100%", // Stretch to the grid cell width
          borderColor: "rgba(156, 163, 175, 0.5)", // Gray-400 at 50% opacity
          borderWidth: "2px",
          color: "rgba(255, 255, 255, 0.78)",
          textTransform: "none", // Preserve text case
          transition: "all 0.3s ease",
          "&:hover": {
            borderColor: "transparent",
            borderImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6) 1",
            color: "rgb(255, 255, 255)",
          },
        }}
      >
        {text}
      </Button>
    </motion.div>
  );
};

export default StretchButton;
