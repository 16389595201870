import {HelpOutline,Message,Dashboard,Insights,Send, Settings,AppRegistration, AccountCircle, QueryStats,PieChartOutlineRounded, ShowChart,Add, Search} from '@mui/icons-material/';


export const links = [
    {
      title: 'Home',
      links: [
        {
          name: 'Dashboard',
          icon: <Dashboard/>,
        },
      ],
    },
  
    {
      title: 'Holders',
      links: [
        {
          name: 'Structure info',
          icon: <PieChartOutlineRounded  />,
        },
        {
          name: 'Unrealized PnL',
          icon: <AppRegistration  />,
        },
      ],
    },
    {
      title: 'Price action',
      links: [
        {
          name: 'Breakdown',
          icon: <Insights  />,
        }
      ],
    },
    {
      title: 'Services',
      links: [
        {
          name: 'Holders chat',
          icon: <Message  />,
        }
      ],
    },
    {
      title: 'General',
      links: [
        {
          name: 'Settings',
          icon: <Settings />,
        },
        {
            name: 'About',
            icon: <HelpOutline />,
        }
      ],
    },
  ];
  