import React from 'react'
import {Link, NavLink} from 'react-router-dom';
import {links} from '../data/rawData';
import visionIcon from '../assets/images/vision_icon.png'; // Adjust the path as needed

import CloseIcon from '@mui/icons-material/Close';
import { useStateContext } from '../contexts/ContextProvider';
import { IconButton } from '@mui/material';

const Sidebar = () => {
    const {activeMenu,setActiveMenu, screenSize} = useStateContext();
  
    const handleCloseSideBar = ( ) => {
      if(activeMenu && screenSize <= 900){
        setActiveMenu(false);
      }
    }
  
    const activeLink = "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg font-medium bg-viola text-gray-300 bg-opacity-5 text-md m-2 no-underline";
    const normalLink  = "barlowSidebar flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-md text-gray-400 hover:bg-light-gray m-2 no-underline";
  
  
    return (
      <div className=' ml-1 h-screen bg-main-dark-bg md:overflow-hidden overflow-auto md:hover:overflow-auto scrollbar-hidden pb-10 '>
      {activeMenu && (<>
        <div className='flex justify-between items-center '>
  
          <Link to ='/' onClick={handleCloseSideBar} className='items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900'>
          <div className="flex items-center space-x-2">
            <img src={visionIcon} alt="Vision Icon" className="h-16 w-16 object-cover" />
            <span className="text-3xl italic opacity-75 text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-green-500 to-blue-500 barlowLogo tracking-wide">
            Tools
            </span>
          </div>
          </Link>
  
          
  
        </div>
        <div className='mt-10'>
          {links.map((item) => (
            <div key={item.title}>
              <p className='text-transparent bg-clip-text bg-gradient-to-r from-purple-500 via-green-500 to-blue-500 barlowSidebar tracking-wide m-3 mt-4 uppercase'> 
                {item.title}
              </p>
              {item.links.map((link) => (
                <NavLink
                  to={`/${link.name}`}
                  key= {item.name}
                  onClick={handleCloseSideBar}
                  className={ ({isActive}) => isActive ? activeLink : normalLink}
                >
                {link.icon}
                <span className='capitalize'>
                    {link.name}
                </span>
                </NavLink>
              ))}
            </div>
          ))}
        </div>
  
      </>)}
  
      </div>
    )
  }

export default Sidebar