import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  { avgEntry: "0-50k", "15min": 0, "30min": 0, "1h": 0, "2h": 0, "4h": 0, "6h": 0, "8h": 0, "12h": 5, "24h": 50, "48h": 400, "5d": 500, "1w": 800 },
  { avgEntry: "50-100k", "15min": 0, "30min": 0, "1h": 0, "2h": 0, "4h": 0, "6h": 350, "8h": 0, "12h": 450, "24h": 550, "48h": 650, "5d": 750, "1w": 850 },
  { avgEntry: "100-150k", "15min": 0, "30min": 0, "1h": 0, "2h": 0, "4h": 0, "6h": 400, "8h": 0, "12h": 500, "24h": 600, "48h": 700, "5d": 800, "1w": 900 },
  { avgEntry: "150-200k", "15min": 0, "30min": 0, "1h": 300, "2h": 350, "4h": 400, "6h": 450, "8h": 500, "12h": 550, "24h": 650, "48h": 750, "5d": 850, "1w": 950 },
  { avgEntry: "200-250k", "15min": 0, "30min": 0, "1h": 350, "2h": 400, "4h": 450, "6h": 500, "8h": 550, "12h": 600, "24h": 700, "48h": 800, "5d": 900, "1w": 1000 },
  { avgEntry: "250-300k", "15min": 0, "30min": 0, "1h": 400, "2h": 450, "4h": 500, "6h": 550, "8h": 600, "12h": 650, "24h": 750, "48h": 850, "5d": 950, "1w": 0 },
  { avgEntry: "300-400k", "15min": 0, "30min": 20, "1h": 450, "2h": 500, "4h": 550, "6h": 600, "8h": 650, "12h": 700, "24h": 800, "48h": 900, "5d": 0, "1w": 0 },
  { avgEntry: "400-500k", "15min": 0, "30min": 250, "1h": 500, "2h": 550, "4h": 600, "6h": 650, "8h": 700, "12h": 750, "24h": 850, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "500-600k", "15min": 0, "30min": 300, "1h": 550, "2h": 600, "4h": 650, "6h": 700, "8h": 750, "12h": 800, "24h": 900, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "600-700k", "15min": 500, "30min": 550, "1h": 600, "2h": 650, "4h": 700, "6h": 750, "8h": 800, "12h": 850, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "700-800k", "15min": 550, "30min": 600, "1h": 650, "2h": 700, "4h": 750, "6h": 800, "8h": 850, "12h": 900, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "800-900k", "15min": 600, "30min": 650, "1h": 700, "2h": 750, "4h": 800, "6h": 850, "8h": 900, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "900k-1M", "15min": 650, "30min": 700, "1h": 750, "2h": 800, "4h": 850, "6h": 900, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "1-1.5M", "15min": 700, "30min": 750, "1h": 800, "2h": 850, "4h": 900, "6h": 0, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "1.5-2M", "15min": 750, "30min": 800, "1h": 850, "2h": 900, "4h": 0, "6h": 0, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "2-3M", "15min": 800, "30min": 850, "1h": 900, "2h": 0, "4h": 0, "6h": 0, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "3-4M", "15min": 850, "30min": 900, "1h": 0, "2h": 0, "4h": 0, "6h": 0, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 },
  { avgEntry: "4-5M", "15min": 900, "30min": 0, "1h": 0, "2h": 0, "4h": 0, "6h": 0, "8h": 0, "12h": 0, "24h": 0, "48h": 0, "5d": 0, "1w": 0 }
];


const StackChart = () => {
  return (
    <div
      style={{
        padding: "10px",
        borderRadius: "4px",
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: "rgba(156, 163, 175, 0.5)",
        transition: "all 0.3s ease",
        backgroundColor: "transparent", // Transparent background
      }}
    >
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={10} // Thinner bars
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.2)" />
          <XAxis dataKey="avgEntry" tick={{ fill: "rgba(255, 255, 255, 0.8)" }} />
          <YAxis tick={{ fill: "rgba(255, 255, 255, 0.8)" }} />
          <Tooltip
            cursor={{fill: 'rgba(34, 197, 94, 0.07)'}}
            contentStyle={{
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              borderColor: "#6b7280",
              color: "#ffffff",
            }}
          />
          <Tooltip wrapperStyle={{ width: 100, backgroundColor: '#ccc' }} />
          <Legend />
            {/* Adjusted Bar keys */}
            <Bar dataKey="1w" stackId="a" fill="#8018e7" />
            <Bar dataKey="5d" stackId="a" fill="#6c20a8" />
            <Bar dataKey="48h" stackId="a" fill="#582eb9" />
            <Bar dataKey="24h" stackId="a" fill="#444ac9" />
            <Bar dataKey="12h" stackId="a" fill="#2f68d9" />
            <Bar dataKey="8h" stackId="a" fill="#3b82f6" />
            <Bar dataKey="6h" stackId="a" fill="#4aa1e5" />
            <Bar dataKey="4h" stackId="a" fill="#5bc0d4" />
            <Bar dataKey="2h" stackId="a" fill="#6ddfb3" />
            <Bar dataKey="1h" stackId="a" fill="#82ca9d" />
            <Bar dataKey="30min" stackId="a" fill="#96c284" />
            <Bar dataKey="15min" stackId="a" fill="	#e2ff68" />


        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default StackChart;
