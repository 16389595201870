import React, { useEffect, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";

const AboutCardMatrix = () => {
  const [selectedText, setSelectedText] = useState("");

  const texts = [
    "After investing countless hours in analyzing meme coin behavior, it was time to deliver some cool research tools.",
    "Since we already pay for APIs and develop tools for our operations, why not host them in one place and collaborate with others?",
    "Once we gather more subscribers, we might issue a token. We’ll keep a record of subscribers’ payments -> points for that scenario...",
    "The lead developer (MA) found design inspiration after buying token that rugged him, named \"Vision\" 'We have the vision'... Then the CTO came, and they rugged as well...",
  ];
  

  useEffect(() => {
    // Select a random text on component mount
    const randomIndex = Math.floor(Math.random() * texts.length);
    setSelectedText(texts[randomIndex]);
  }, []);

  return (
    <div
      className="relative w-full h-full"
      style={{ fontFamily: "'Press Start 2P', monospace", backgroundColor: "transparent" }}
    >
      <motion.div
        className="w-full max-w-[100%] text-purple-500 bg-transparent"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {selectedText && ( // Render only if a text is selected
          <TypeAnimation
            sequence={[
              selectedText, // Display the randomly selected text
              2000, // Pause for 2 seconds after typing
            ]}
            wrapper="p"
            speed={50}
            style={{
              color: "#A020F0",
              fontSize: "16px",
              lineHeight: "1.5",
              textAlign: "left",
            }}
            repeat={Infinity} // Keep repeating the animation
          />
        )}
      </motion.div>
    </div>
  );
};

export default AboutCardMatrix;
