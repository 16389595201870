import React from "react";
import shapeImage from "../assets/images/shape.webp"; // Correct relative path

const Text3D = ({ text }) => {
  return (
    <div className="relative w-full h-full flex items-center justify-between">

      {/* Left Image with Gradient Overlay */}
      {/* Left Image with Full Gradient Overlay */}
      <div className="relative w-24 h-24">
        <img
          src={shapeImage}
          alt="Visual Element"
          className="w-full h-full"
        />
        <div
          className="absolute inset-0"
          style={{
            background: "radial-gradient(circle at center, transparent, #100f14 77%)",
            zIndex: 1,
          }}
        />
      </div>

      {/* Outlined Text */}
      <div className="relative w-fit">
  {/* Main Gradient Text */}
  <div
    className="text-6xl barlowThick text-transparent bg-clip-text"
    style={{
      backgroundImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6)", // Gradient color
      WebkitBackgroundClip: "text", // Clips the gradient to the text
      WebkitTextStroke: "3px transparent", // Transparent stroke
      WebkitTextFillColor: "#100f14", // Ensures fill is dark
    }}
  >
    {text}
  </div>

  {/* Shadow with Gradient Stroke */}
  <div
    className="absolute inset-0 text-6xl barlowThick text-transparent bg-clip-text pointer-events-none"
    style={{
      
      backgroundImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6)", // Gradient shadow
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent", // Fully transparent fill
      WebkitTextStroke: "3px transparent", // Transparent to show the gradient
      transform: "translate(2px, 2px)", // Offset for shadow
      zIndex: -1, // Push shadow behind text
    }}
  >
    {text}
  </div>
</div>

      {/* Left Image with Full Gradient Overlay */}
      <div className="relative w-24 h-24">
        <img
          src={shapeImage}
          alt="Visual Element"
          className="w-full h-full"
        />
        <div
          className="absolute inset-0"
          style={{
            background: "radial-gradient(circle at center, transparent, #100f14 80%)",
            zIndex: 1,
          }}
        />
      </div>

    </div>
  );
};

export default Text3D;
