import React from "react";
import { motion } from "framer-motion";
import { Telegram } from "@mui/icons-material";
import "./ContactCard.css"; // New CSS file for this component
import { Typography, Divider, Button } from "@mui/material";

const rightEntry = {
  hidden: { x: 100, opacity: 0 },
  visible: { x: 0, opacity: 1, transition: { duration: 0.8 } },
};

const ContactCard = () => {
  return (
    <motion.div
      className="contact-card grid grid-rows-3 desktop1440:grid-rows-4 items-center text-white h-[90%] mt-[5%] w-[90%] ml-[5%]"
      variants={rightEntry}
      initial="hidden"
      animate="visible"
    >
      {/* Title Section with Divider */}
      <div className="flex flex-col items-center my-2">
        <Typography className="text-center font-bold pt-4 text-base desktop1680:text-xl desktop1920:text-2xl desktop2560:text-3xl">
          Want a custom tool?
        </Typography>
        <Divider className="w-[80%] border-gray-600 my-2" />
      </div>

      {/* Text Section */}
      <div className="flex justify-center items-center">
        <Typography className="text-center text-sm px-2 desktop1920:text-lg">
          New tools are constantly under development. We can develop tools by your <strong>vision</strong>.
        </Typography>
      </div>

      {/* Optional Additional Text */}
      <div className="hidden desktop1440:flex justify-center items-center">
        <Typography className="text-center text-sm px-2 desktop1920:text-lg">
          Can't wait to hear what is on your mind...
        </Typography>
      </div>

      {/* Button */}
      <div className="flex justify-center items-start">
        <Button
          variant="outlined"
          className="px-8 text-sm desktop1920:text-lg flex items-center"
          sx={{
            borderColor: "rgba(156, 163, 175, 0.5)",
            borderWidth: "2px",
            color: "rgba(156, 163, 175, 1)",
            textTransform: "none",
            transition: "all 0.3s ease",
            "&:hover": {
              borderWidth: "2px",
              borderColor: "transparent",
              borderImage: "linear-gradient(to right, #8018e7, #10b981, #3b82f6) 1",
              color: "rgba(255, 255, 255, 1)",
            },
          }}
        >
          <Telegram className="mr-2 text-lg desktop1920:text-xl " />
          Contact
        </Button>
      </div>
    </motion.div>
  );
};

export default ContactCard;
